import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

function Privacy() {
  return (
    <Layout>
      <SEO
        keywords={[
          `gatsby`,
          `tailwind`,
          `react`,
          `tailwindcss`,
          "giuseppe",
          "gangi",
        ]}
        title={`Privacy Policy & Terms of Conditions`}
      />

      <section className="flexmd:flex-row">
        <p className="text-xl text-center mb-8">{`Privacy Policy & Terms of Conditions`}</p>
        <div className="text-l">
          <p className="mb-3">
            Your privacy is important to us. It is Giuseppe Gangi’s policy to
            respect your privacy regarding any information we may collect from
            you across our website,&nbsp;
            <a className="hover:underline" href="http://giuseppe.xyz">
              http://giuseppe.xyz
            </a>
            , and other sites we own and operate.
          </p>
          <p className="mb-3">
            We only ask for personal information when we truly need it to
            provide a service to you. We collect it by fair and lawful means,
            with your knowledge and consent. We also let you know why we’re
            collecting it and how it will be used.
          </p>
          <p className="mb-3">
            We only retain collected information for as long as necessary to
            provide you with your requested service. What data we store, we’ll
            protect within commercially acceptable means to prevent loss and
            theft, as well as unauthorised access, disclosure, copying, use or
            modification.
          </p>
          <p className="mb-3">
            We don’t share any personally identifying information publicly or
            with third-parties, except when required to by law.
          </p>
          <p className="mb-3">
            Our website may link to external sites that are not operated by us.
            Please be aware that we have no control over the content and
            practices of these sites, and cannot accept responsibility or
            liability for their respective privacy policies.
          </p>
          <p className="mb-3">
            You are free to refuse our request for your personal information,
            with the understanding that we may be unable to provide you with
            some of your desired services.
          </p>
          <p className="mb-3">
            Your continued use of our website will be regarded as acceptance of
            our practices around privacy and personal information. If you have
            any questions about how we handle user data and personal
            information, feel free to contact us.
          </p>
          <p className="mb-3">This policy is effective as of 1 Dec 2020.</p>
          <p className="h2 font-bold my-8">Terms of Service</p>
          <p className="h3 font-bold my-4">1. Terms</p>
          <p className="mb-3">
            By accessing the website at&nbsp;
            <a href="http://giuseppe.xyz">http://fh-digital.org</a>, you are
            agreeing to be bound by these terms of service, all applicable laws
            and regulations, and agree that you are responsible for compliance
            with any applicable local laws. If you do not agree with any of
            these terms, you are prohibited from using or accessing this site.
            The materials contained in this website are protected by applicable
            copyright and trademark law.
          </p>
          <p className="h3 font-bold my-4">2. Use License</p>
          <ol>
            <li>
              Permission is granted to temporarily download one copy of the
              materials (information or software) on Giuseppe Gangi’s website
              for personal, non-commercial transitory viewing only. This is the
              grant of a license, not a transfer of title, and under this
              license you may not:
              <ol type="i">
                <li>modify or copy the materials;</li>
                <li>
                  use the materials for any commercial purpose, or for any
                  public display (commercial or non-commercial);
                </li>
                <li>
                  attempt to decompile or reverse engineer any software
                  contained on Giuseppe Gangi’s website;
                </li>
                <li>
                  remove any copyright or other proprietary notations from the
                  materials; or
                </li>
                <li>
                  transfer the materials to another person or “mirror” the
                  materials on any other server.
                </li>
              </ol>
            </li>
            <li>
              This license shall automatically terminate if you violate any of
              these restrictions and may be terminated by Giuseppe Gangi at any
              time. Upon terminating your viewing of these materials or upon the
              termination of this license, you must destroy any downloaded
              materials in your possession whether in electronic or printed
              format.
            </li>
          </ol>
          <p className="h3 font-bold my-4">3. Disclaimer</p>

          <ol>
            <li>
              The materials on Giuseppe Gangi’s website are provided on an ‘as
              is’ basis. Giuseppe Gangi makes no warranties, expressed or
              implied, and hereby disclaims and negates all other warranties
              including, without limitation, implied warranties or conditions of
              merchantability, fitness for a particular purpose, or
              non-infringement of intellectual property or other violation of
              rights.
            </li>
            <li>
              Further, Giuseppe Gangi does not warrant or make any
              representations concerning the accuracy, likely results, or
              reliability of the use of the materials on its website or
              otherwise relating to such materials or on any sites linked to
              this site.
            </li>
          </ol>

          <p className="h3 font-bold my-4">4. Limitations</p>

          <p className="mb-3">
            In no event shall Giuseppe Gangi or its suppliers be liable for any
            damages (including, without limitation, damages for loss of data or
            profit, or due to business interruption) arising out of the use or
            inability to use the materials on Giuseppe Gangi’s website, even if
            FH Digital or a Giuseppe Gangi authorized representative has been
            notified orally or in writing of the possibility of such damage.
            Because some jurisdictions do not allow limitations on implied
            warranties, or limitations of liability for consequential or
            incidental damages, these limitations may not apply to you.
          </p>

          <p className="h3 font-bold my-4">5. Accuracy of materials</p>

          <p className="mb-3">
            The materials appearing on Giuseppe Gangi’s website could include
            technical, typographical, or photographic errors. Giuseppe Gangi
            does not warrant that any of the materials on its website are
            accurate, complete or current. Giuseppe Gangi may make changes to
            the materials contained on its website at any time without notice.
            However FH Digital does not make any commitment to update the
            materials.
          </p>

          <p className="h3 font-bold my-4">6. Links</p>

          <p className="mb-3">
            Giuseppe Gangi has not reviewed all of the sites linked to its
            website and is not responsible for the contents of any such linked
            site. The inclusion of any link does not imply endorsement by
            Giuseppe Gangi of the site. Use of any such linked website is at the
            user’s own risk.
          </p>

          <p className="h3 font-bold my-4">7. Modifications</p>

          <p className="mb-3">
            Giuseppe Gangi may revise these terms of service for its website at
            any time without notice. By using this website you are agreeing to
            be bound by the then current version of these terms of service.
          </p>

          <p className="h3 font-bold my-4">8. Governing Law</p>

          <p className="mb-3">
            These terms and conditions are governed by and construed in
            accordance with the laws of Germany and you irrevocably submit to
            the exclusive jurisdiction of the courts in that State or location.
          </p>
        </div>
      </section>
    </Layout>
  );
}

export default Privacy;
